import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import http, {COMPANY_NAME} from "@/utils/http";
import qs from "qs";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/pdf',
        name: 'PDF',
        component: () => import('@/views/PDF.vue'),
    },
    {
        path: '/pdfs',
        name: 'PDFs',
        component: () => import('@/views/PDFs.vue'),
    },
    {
        path: '/downloads',
        name: 'Downloads',
        component: () => import('@/views/Downloads.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        // meta: {keepAlive: true}
    },
    {
        path: '/products',
        name: 'Products',
        component: () => import('@/views/Products.vue')
    },
    {
        path: '/product',
        name: 'Product',
        component: () => import('@/views/Product.vue')
    },
    {
        path: '/goods',
        name: 'Goods',
        component: () => import('@/views/Goods.vue')
    },
    {
        path: '/s',
        name: 'AppointmentSurvey',
        component: () => import('@/views/Survey.vue')
    },
    {
        path: '/survey',
        name: 'Survey',
        component: () => import('@/views/Survey.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_center',
        name: 'MyCenter',
        component: () => import('@/views/MyCenter.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/personal_info',
        name: 'PersonalInfo',
        component: () => import('@/views/PersonalInfo.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_order',
        name: 'MyOrder',
        component: () => import('@/views/MyOrder.vue'),
        meta: {requireAuth: true, keepAlive: true}
    },
    {
        path: '/my_order_detail',
        name: 'MyOrderDetail',
        component: () => import('@/views/MyOrderDetail.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_order_gift',
        name: 'MyOrderGift',
        component: () => import('@/views/MyOrderGift.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_tester',
        name: 'MyTester',
        component: () => import('@/views/MyTester.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_tester_edit',
        name: 'MyTesterEdit',
        component: () => import('@/views/MyTesterEdit.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_appointment',
        name: 'MyAppointment',
        component: () => import('@/views/MyAppointment.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_tester_report',
        name: 'MyTesterReport',
        component: () => import('@/views/MyTesterReport.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_appointment_edit',
        name: 'MyAppointmentEdit',
        component: () => import('@/views/MyAppointmentEdit.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/product_order',
        name: 'ProductOrder',
        component: () => import('@/views/ProductOrder.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/goods_order',
        name: 'GoodsOrder',
        component: () => import('@/views/GoodsOrder.vue'),
        meta: {requireAuth: true, keepAlive: true}
    },
    {
        path: '/my_goods_order_detail',
        name: 'MyGoodsOrderDetail',
        component: () => import('@/views/MyGoodsOrderDetail.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/info_column',
        name: 'InfoColumn',
        component: () => import('@/views/InfoColumn.vue')
    },
    {
        path: '/info',
        name: 'Info',
        component: () => import('@/views/Info.vue'),
        meta: {scrollToTop: true}
    },
    {
        path: '/instructions',
        name: 'Instructions',
        component: () => import('@/views/Instructions.vue'),
        meta: {scrollToTop: true}
    },

    {
        path: '/my_address',
        name: 'MyAddress',
        component: () => import('@/views/MyAddress.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/my_address_edit',
        name: 'MyAddressEdit',
        component: () => import('@/views/MyAddressEdit.vue'),
        meta: {requireAuth: true}
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {

    let requireAuth = to.meta.requireAuth;
    if (requireAuth) {
        let payload = qs.stringify({path: to.fullPath});
        http.post('/api/public/check_token', payload).then(res => {
            // console.log(res)
            if(res.data.result) {
                var tokenExpiredAt = res.data.extra.tokenExpiredAt;
                var tokenIssuedAt = res.data.extra.tokenIssuedAt;
                // console.log("now:"+new Date().getTime())
                // console.log('exp:'+tokenExpiredAt)
                const c = tokenExpiredAt - new Date().getTime();
                const c2 = new Date().getTime() - tokenIssuedAt;
                if (c < 10 * 60000 || c2 > 12 * 3600000) {
                    //看过期时间，低于10分钟，就自动续期 或者issue时间超过12小时，就自动续期
                    //console.log('refresh token now, c: ' + c);
                    http.get('/api/personal/refresh_token').then(res2 => {
                        if(res2.data.result){
                            localStorage.setItem("token", res2.data.extra.token);
                            localStorage.setItem("tokenExpiredAt", res2.data.extra.tokenExpiredAt);
                            localStorage.setItem("tokenIssuedAt", res2.data.extra.tokenIssuedAt);
                        }
                    })
                }
                nextStep(to, from, next);
            }
        });
    } else {
        nextStep(to, from, next);
    }
});

function nextStep(to, from, next) {
    let scrollToTop = to.meta.scrollToTop;
    if (scrollToTop) {
        window.scrollTo(0, 0);
    }

    Vue.nextTick(() => {
        document.title = to.meta.title || COMPANY_NAME;
    });

    next();
}

export default router
